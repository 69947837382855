import React, { useEffect, useState } from 'react';
import mixpanel from '../Services/Mixpanel.js';

import { useTheme } from '../Theme/ThemeContext';
import '../Theme/Container.css';
import { useH5Style, floatAnimation } from '../Theme/ThemeStyles';
import Spacer from '../Components/Spacer';
import TopClouds from '../Components/TopClouds';
import PrimaryButton from '../Components/PrimaryButton';
import { Img } from 'react-image'
import CatarotRoute from '../CatarotRoute';
import { useNavigate, useLocation } from 'react-router-dom';

const LoadingAdState = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const question = location.state?.question || '';
  const cards = location.state?.cards || [];
  const theme = useTheme();

  const [result, setResult] = useState('');
  const [fetchDataCompleted, setFetchDataCompleted] = useState(false);
  const [error, setError] = useState(null);

  const containerStyle = {
    display: 'flex',
    animation: 'appear 0.5s ease-out',
    maxWidth: '720px',
    padding: '20px',
    margin: 'auto',
    zIndex: 0
  };

  const errorStyle = {
    ...useH5Style()
  }

  useEffect(() => {
    // Create a script element
    const script = document.createElement('script');

    // Set script attributes
    script.async = true;
    script.setAttribute('data-cfasync', 'false');
    script.src = '//thubanoa.com/1?z=7020209';

    // Append the script to the document body
    document.body.appendChild(script);

    // Clean up the script tag when the component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures the effect runs only once on mount


  useEffect(() => {
    const trimmedQuestion = question.trim();
    if (trimmedQuestion === '' || trimmedQuestion.length < 1 || cards.length < 3) {
      window.location.href = 'https://www.catarot.net';
    } else {
      const body = JSON.stringify({
        cards: cards,
        question: question
      });

      const fetchData = async () => {
        try {
          const response = await fetch('https://catarot-server-1c9b8471fbd5.herokuapp.com/api/tarotRead', {
            method: 'POST',
            body: body,
            headers: {
              'Content-Type': 'application/json',
            },
          });

          const data = await response.json();
          setResult(data.result);
          setFetchDataCompleted(true);
          mixpanel.track('fetching_result_success', {
            source: 'loading_ad_state',
          });
        } catch (error) {
          setError('An error occurred while reading your cards. Please try again later.');
          mixpanel.track('fetching_result_error', {
            source: 'loading_ad_state',
            error: error
          });
        }
      };

      fetchData();

      return () => {};
    }
  }, []);

  const handleContinueClick = () => {
    mixpanel.track('continue_clicked', {
      source: 'loading_ad_state',
    });
    navigate(CatarotRoute.RESULT, { state: { question: question, cards: cards, result: result }});
  };

  const buttonStyle = {
    width: '100%'
  };

  const buttonDisabledStyle = {
    color: theme.primaryActionTextColor + '50',
    backgroundColor: theme.primaryActionBackgroundColor + '50',
    width: '100%'
  };

  const h5Style = useH5Style();

  const titleStyle = {
    ...h5Style,
    color: theme.primaryActionBackgroundColor,
  };

  return (
    <div>
    <div className="container" style={containerStyle}>
    <div style={{
      width: '100%',
      flex: '1',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
    <TopClouds color={theme.darkBackground}/>
    <Img
    src='/cat_reading.png'
    alt="The cat is reading"
    style={{
      position: 'relative',
      maxWidth: '100%',
      maxHeight: '100%',
      zIndex: '100',
      ...floatAnimation
    }}
    />
    </div>
    <Spacer spaces={2}/>
      { error ? (
        <div style={errorStyle}>{ error }</div>
      ) : (
        <PrimaryButton onClick={handleContinueClick} disabled={!fetchDataCompleted} style={buttonStyle} disabledStyle={buttonDisabledStyle}>
          { fetchDataCompleted ? "See your reading" : "The cat is reading your cards..." }
        </PrimaryButton>
      )}
    </div>
    </div>
  );
};

export default LoadingAdState;
